import React from "react";
import SEO from "@src/components/SEO";
import NavSection from "@src/components/partials/studentstories/NavSection";
import HeroSection from "@src/components/partials/studentstories/HeroSection";
import FeaturesSection from "@src/components/partials/studentstories/FeaturesSection";
import PortfolioSection from "@src/components/partials/studentstories/PortfolioSection";
import ExcitedSection from "@src/components/partials/studentstories/ExcitedSection";
import "react-slideshow-image/dist/styles.css";

function StudentStories() {
  return (
    <>
      <SEO
        title="page_titles.studentstories"
        description="page_descriptions.studentstories"
        image="https://static.classdojo.com/img/page_studentportfolios/PortfoliosSocialAsset.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Student-led portfolios on ClassDojo",
          description: "Your students will love sharing classwork with their parents :)",
          image: "https://static.classdojo.com/img/page_studentportfolios/PortfoliosSocialAsset.png",
        }}
      />
      <NavSection />
      <HeroSection />
      <FeaturesSection />
      <PortfolioSection />
      <ExcitedSection />
    </>
  );
}

export default StudentStories;
